import React from 'react'
import styles from './styles'

const ServiceCard = ({props}) => {
    const cardContainer = styles().cardContainer
    const cardTitle = styles().cardTitle
    const titleText = styles().titleText
    const descriptionContainer = styles().descriptionContainer
    const cardDescription = styles().cardDescription
    const descText = styles().descText
    const bodyIcon = styles().bodyIcon

    return(
        <div className={`${cardContainer}`}>
            <div className={`${bodyIcon}`}>
                {props.icon}
            </div>
            <div className={`${descriptionContainer}`}>
                <div className={`${cardTitle}`}>
                    <span className={`${titleText}`}>{props.title}</span>
                </div>
                <div className={`${cardDescription}`}>
                    <span className={`${descText}`}>{props.text}</span>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard