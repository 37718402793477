import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    wcuContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            paddingTop:'20%',
            backgroundColor: MainTheme.palette.primary.dark_full
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"2%",
            paddingBottom:"2%",
            backgroundColor: MainTheme.palette.primary.dark_full
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"2%",
            paddingBottom:"2%",
            backgroundColor: MainTheme.palette.primary.dark_full
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'100%',
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'100%',
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'80%',
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
    },
    descriptionContent:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'90%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'4%'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
    },
    descriptionText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "50px",
            width:'100%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            width:'40%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            width:'40%'
        },
    },
    divCardsContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"90%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
    },
}))