import React from 'react'
import styles from './styles'

const TechCard = ({props}) => {
    const cardContainer = styles().cardContainer
    const cardTitle = styles().cardTitle
    const titleText = styles().titleText
    const bodyIcon = styles().bodyIcon

    return(
        <div className={`${cardContainer}`}>
            <div className={`${bodyIcon}`}>
                {props.icon}
            </div>
            <div className={`${cardTitle}`}>
                <span className={`${titleText}`}>{props.title}</span>
            </div>
        </div>
    )
}

export default TechCard