import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    headerContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            backgroundColor: MainTheme.palette.secondary.dark,
            width:'100%',
            display:'none',
            flexDirection:'row',
        },
        [MainTheme.breakpoints.up('md')]: {
            backgroundColor: MainTheme.palette.secondary.dark,
            width:'100%',
            display:'flex',
            flexDirection:'row',
        },
        [MainTheme.breakpoints.up('lg')]: {
            backgroundColor: MainTheme.palette.secondary.dark,
            width:'100%',
            display:'flex',
            flexDirection:'row',
        },
    },
    headerContainerLeft:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'30%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingLeft: '15%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingLeft: '15%',
        },
    },
    headerContainerRight:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'80%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: '15%'      
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: '15%'         
        },
    },
    socialButton:{
        [MainTheme.breakpoints.down('sm')]: {
            padding: '1%',
            display:'flex',
            alignItems:'center',
            color: MainTheme.palette.secondary.contrastText,
        },
        [MainTheme.breakpoints.up('md')]: {
            padding: '1%',
            display:'flex',
            alignItems:'center',
            color: MainTheme.palette.secondary.contrastText,
        },
        [MainTheme.breakpoints.up('lg')]: {
            padding: '1%',
            display:'flex',
            alignItems:'center',
            color: MainTheme.palette.secondary.contrastText,
        },
    },
    textDiv:{
        [MainTheme.breakpoints.down('sm')]: {
        },
        [MainTheme.breakpoints.up('md')]: {
            paddingLeft: '15px',
            paddingRight: '5px'
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingLeft: '15px',
            paddingRight: '5px'
        },
    },
}))