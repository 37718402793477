import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 400
const height = 300
const marginLeft = 10
const marginRight = 10

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width: `${width}px`,
            height: `${height}px`,
            position:'relative',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width: `${width}px`,
            height: `${height}px`,
            position:'relative',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${width}px`,
            height: `${height}px`,
            position:'relative',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
    },
    boxContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width: `${width/1.2}px`,
            height: `${height/3}px`,
            position:'absolute',
            bottom:'-30px',    
            overflow:'hidden',       
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width: `${width/1.2}px`,
            height: `${height/3}px`,
            position:'absolute',
            bottom:'-30px',    
            overflow:'hidden',       
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${width/1.2}px`,
            height: `${height/3}px`,
            position:'absolute',
            bottom:'-30px',    
            overflow:'hidden',       
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
    },
    cardTitle:{
        [MainTheme.breakpoints.down('sm')]: {     
            width:"80%",
            position:'absolute',
            top:'20px',
            left:'20px',
        },
        [MainTheme.breakpoints.up('md')]: {    
            width:"80%",
            position:'absolute',
            top:'20px',
            left:'20px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'20px',
            left:'20px',
        },
    },
    titleText:{
        [MainTheme.breakpoints.down('sm')]: {    
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: { 
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
    },
    cardDescription:{
        [MainTheme.breakpoints.down('sm')]: {    
            width:"80%",
            position:'absolute',
            top:'50px',
            left:'20px',
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"80%",
            position:'absolute',
            top:'50px',
            left:'20px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'50px',
            left:'20px',
        },
    },
    descText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText,
            fontSize:'14px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('md')]: { 
            color: MainTheme.palette.primary.contrastText,
            fontSize:'14px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color: MainTheme.palette.primary.contrastText,
            fontSize:'14px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
    },
    quarterOfCircle:{
        [MainTheme.breakpoints.down('sm')]: {
            position:'absolute',
            bottom:`-${height/4}px`,
            right:`-${height/4}px`,
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:`${height/2}px`,
            height:`${height/2}px`,
            borderRadius:'200px',
        },
        [MainTheme.breakpoints.up('md')]: {
            position:'absolute',
            bottom:`-${height/4}px`,
            right:`-${height/4}px`,
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:`${height/2}px`,
            height:`${height/2}px`,
            borderRadius:'200px',
        },
        [MainTheme.breakpoints.up('lg')]: {
            position:'absolute',
            bottom:`-${height/4}px`,
            right:`-${height/4}px`,
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:`${height/2}px`,
            height:`${height/2}px`,
            borderRadius:'200px',
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'2%',
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'2%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'2%',
        },
    },
}))