import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 150
const height = 150

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width: `90%`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.contrastText}`,
        },
        [MainTheme.breakpoints.up('md')]: {
            width: `${width}px`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:'20px',
            marginRight:'20px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.contrastText}`,
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${width}px`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:'20px',
            marginRight:'20px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.contrastText}`,
        },
    },
    cardTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {    
            width:"90%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"90%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
    },
    titleText:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            fontSize:'20px',
            textAlign:'center',
            justifyContent:'center',
        },
        [MainTheme.breakpoints.up('md')]: {     
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'row',
            fontSize:'20px',
            textAlign:'center',
            justifyContent:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'row',
            fontSize:'20px',
            textAlign:'center',
            justifyContent:'center',
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            paddingBottom:'5%',
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            marginTop:'20%',
            marginBottom:'10%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            marginTop:'20%',
            marginBottom:'10%'
        },
    },
}))