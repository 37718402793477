import React from 'react'
import styles from './styles'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Button} from '@material-ui/core'
import slidehome1 from '../../../assets/slide1-home1.jpg'
import slidehome2 from '../../../assets/slide2-home1.jpg'
import slidehome3 from '../../../assets/slide3-home1.jpg'
import '../../MainTheme/theme.css'

const SliderBody = () => {
    var items = [
        {
            title: "// Ciclo completo de desarrollo de software",
            descriptionFirst: "DESDE LA IDEA",
            descriptionSecond: "HACIA EL PRODUCTO",
            descriptionThird:"Contamos con un staff altamente calificado de profesionales en software y sistemas que le darán forma a tu idea y vida a tu producto",
            //img: <img src={slidehome1} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
            img:slidehome1
        },
        {
            title: "// Creamos productos digitales competitivos",
            descriptionFirst: "DESARROLLO",
            descriptionSecond: "END-TO-END",
            descriptionThird:"Iniciamos el desarrollo de sistemas complejos desde la idea principal, atravesando varias etapas de desarrollo hasta obtener el producto final",
            //img: <img src={slidehome2} width="100%" alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
            img:slidehome2
        },
        {
            title: "// Servicios de alta calidad",
            descriptionFirst: "TIEMPOS REDUCIDOS",
            descriptionSecond: "DE DESARROLLO",
            descriptionThird:"Usamos metodologías áglies para el desarrollo de sistemas complejos",
            //img: <img src={slidehome3} width="100%" alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
            img:slidehome3
        }
    ]
    return (
        <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={5000} transitionTime={500} showIndicators={true} showArrows={false} showStatus={false} swipeable={true} stopOnHover={true}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    const carouselContainer = styles().carouselContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const descriptionFirstContent = styles().descriptionFirstContent
    const descriptionSecondContent = styles().descriptionSecondContent
    const descriptionThirdContent = styles().descriptionThirdContent
    const buttonContainer = styles().buttonContainer

    return (
        <div className={`${carouselContainer} noselect text-left`} style={{backgroundImage: `url(${props.item.img})`}}>
            {/*props.item.img*/}
            <div className={`${divTextContainer}`}>
                <div className={`${titleContent}`}>
                    <h2>{props.item.title}</h2>
                </div>
                <div className={`${descriptionFirstContent}`}>
                    <span>{props.item.descriptionFirst}</span>
                </div>
                <div className={`${descriptionSecondContent}`}>
                    <span>{props.item.descriptionSecond}</span>
                </div>
                <div className={`${descriptionThirdContent}`}>
                    <span>{props.item.descriptionThird}</span>
                </div>
                {/*<div className={`${buttonContainer}`}>
                    <Button variant="contained" color="primary">
                        LEARN MORE
                    </Button>
                </div>*/}
            </div>
        </div>
    )
}

export default SliderBody