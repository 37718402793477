import React from 'react'
import Header from './Header'
import MenuBar from './MenuBar'
import SliderBody from './SliderBody'
import SliderClients from './SliderClients'
import BodyMiddle from './BodyMiddle'
import WhyChooseUs from './whyChooseUs'
import OurService from './ourService'
import CaseStudies from './caseStudies'
import TechIndex from './techIndex'
import Customers from './wwcustomers'
import Footer from './footer'

export const Home = () => {
    return (
        <>
            <Header />
            <MenuBar />
            <SliderBody />
            <SliderClients />
            <BodyMiddle />
            <WhyChooseUs />
            <OurService />
            <CaseStudies />
            <TechIndex />
            <Customers />
            <Footer />
        </>
    )
}

export default Home