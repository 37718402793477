import React from 'react'
import styles from './styles'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css"
import client1 from '../../../assets/client1.svg'
import client2 from '../../../assets/ADR-Logo.png'
import client3 from '../../../assets/client3.svg'
import client5 from '../../../assets/client5.svg'
import client6 from '../../../assets/client6.svg'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const SliderClientes = () => {
    const sliderContainer = styles().sliderContainer
    const carouselContainer = styles().carouselContainer

    var items = [
        {
            img: <img src={client1} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
        },
        {
            img: <img src={client2} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
        },
        {
            img: <img src={client3} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
        },
        {
            img: <img src={client5} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
        },
        {
            img: <img src={client6} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
        }
    ]
    return (
        <div className={`${sliderContainer}`}>
            <div className={`${carouselContainer}`}>
                <Carousel centermode={false} swipeable={false} draggable={false} responsive={responsive} ssr={false} infinite={true} autoPlay={true} autoPlaySpeed={4000} removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}>
                    {
                        items.map( (item, i) => <Item key={i} item={item} /> )
                    }
                </Carousel>
            </div>
        </div>
    )
}

function Item(props)
{
    const carouselItems = styles().carouselItems

    return (
        <div className={`${carouselItems}`}>
            {props.item.img}
        </div>
    )
}

export default SliderClientes