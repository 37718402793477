import React from 'react'
import styles from './styles'
import {Carousel} from '3d-react-carousal';
import CustomerCard from '../utils/customerCard'
import background from "../../../assets/bg-maps-dots.jpg";
import adrLogo from "../../../assets/ADR-Logo.png";
import '../../MainTheme/theme.css'

const cards = [
    {   
        index: '01',
        companyName: 'ADR TECNOLOGY',
        companyPosition: 'Gerencia General',
        companyDescription: 'Scientific Software es nuestro mejor socio de software. Tienen soporte técnico y expertos en software internos que se dedican a trabajar con cualquier tipo de cliente corporativo y sin fines de lucro para definir las necesidades únicas de su organización, desarrollar la solución ideal y brindar soporte constante durante la implementación y más allá.',
        logo: adrLogo
    },
    {   
        index: '02',
        companyName: 'ADR TECNOLOGY',
        companyPosition: 'Gerencia General',
        companyDescription: 'Scientific Software es nuestro mejor socio de software. Tienen soporte técnico y expertos en software internos que se dedican a trabajar con cualquier tipo de cliente corporativo y sin fines de lucro para definir las necesidades únicas de su organización, desarrollar la solución ideal y brindar soporte constante durante la implementación y más allá.',
        logo: adrLogo
    }
]

let slides = [
    <CustomerCard key={'01'} props={cards[0]} />,
    <CustomerCard key={'02'} props={cards[1]} />,
];

const Customers = () => {
    const wwContainer = styles().wwContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const descriptionContent = styles().descriptionContent
    const descriptionText = styles().descriptionText
    const divCardsContainer = styles().divCardsContainer

    return(
        <div className={`${wwContainer}`} style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}>
            <div className={`${divTextContainer}`}>
                <div className={`${titleContent}`}>
                    <span>// NUESTROS CLIENTES</span>
                </div>
                <div className={`${descriptionContent}`}>
                    <div className={`${descriptionText}`}>
                        <span>Nuestro trabajo está avalado por nuestros clientes</span>
                    </div>
                </div>
            </div>
            <div className={`${divCardsContainer} noselect`}>
                {/*<Carousel slides={slides}/>*/}
            </div>
        </div>
    )
}

export default Customers