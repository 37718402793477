import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 600
const height = 300

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width: `100%`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            marginLeft:'20px',
            marginRight:'20px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.primary.light_full}`,
            backgroundColor: MainTheme.palette.secondary.contrastText
        },
        [MainTheme.breakpoints.up('md')]: {
            width: `${width}px`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            marginLeft:'20px',
            marginRight:'20px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.primary.light_full}`,
            backgroundColor: MainTheme.palette.secondary.contrastText
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${width}px`,
            height: `${height}px`,
            display: 'flex',
            flexDirection: 'column',
            marginLeft:'20px',
            marginRight:'20px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.primary.light_full}`,
            backgroundColor: MainTheme.palette.secondary.contrastText
        },
    },
    header:{
        [MainTheme.breakpoints.down('sm')]: {   
            width:"100%",
            display:'flex',
            flexDirection: 'column',
            justifyContent:'center',
            alignItems:'center',
            paddingTop:'30px'
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            paddingTop:'30px'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            paddingTop:'30px'
        },
    },
    logo:{
        [MainTheme.breakpoints.down('sm')]: {        
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {       
            width:"20%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center',
            paddingLeft:'4%'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"20%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center',
            paddingLeft:'4%'
        },
    },
    identifier:{
        [MainTheme.breakpoints.down('sm')]: {  
            width:"100%",
            display:'flex',
            flexDirection: 'column',
            justifyContent:'center',
            alignItems:'center'
        },
        [MainTheme.breakpoints.up('md')]: {     
            width:"75%",
            display:'flex',
            flexDirection: 'column',
            justifyContent:'center',
            paddingLeft:'5%'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"75%",
            display:'flex',
            flexDirection: 'column',
            justifyContent:'center',
            paddingLeft:'5%'
        },
    },
    companyName:{
        [MainTheme.breakpoints.down('sm')]: {    
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
        [MainTheme.breakpoints.up('md')]: {       
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
    },
    companyPosition:{
        [MainTheme.breakpoints.down('sm')]: {        
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'center',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'2%',
            paddingLeft:'2%',
        },
    },
    companyNameText:{
        [MainTheme.breakpoints.down('sm')]: {    
            color:MainTheme.palette.secondary.main,
            fontSize:'18px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {      
            color:MainTheme.palette.secondary.main,
            fontSize:'24px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.secondary.main,
            fontSize:'24px',
            fontWeight:'bold'
        },
    },
    companyPositionText:{
        [MainTheme.breakpoints.down('sm')]: {     
            color:MainTheme.palette.secondary.light_full,
            fontSize:'16px',
        },
        [MainTheme.breakpoints.up('md')]: {       
            color:MainTheme.palette.secondary.light_full,
            fontSize:'16px',
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.secondary.light_full,
            fontSize:'16px',
        },
    },
    description:{
        [MainTheme.breakpoints.down('sm')]: {       
            width:"80%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'4%',
            paddingLeft:'10%'
        },
        [MainTheme.breakpoints.up('md')]: {    
            width:"80%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'4%',
            paddingLeft:'10%'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            display:'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            paddingTop:'4%',
            paddingLeft:'10%'
        },
    },
    descriptionText:{
        [MainTheme.breakpoints.down('sm')]: {      
            color:MainTheme.palette.secondary.light_full,
            fontSize:'13px',
            textAlign:'justify'
        },
        [MainTheme.breakpoints.up('md')]: { 
            color:MainTheme.palette.secondary.light_full,
            fontSize:'16px',
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.secondary.light_full,
            fontSize:'16px',
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            marginTop:'20%',
            marginBottom:'10%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            marginTop:'20%',
            marginBottom:'10%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText, 
            width:'100%',
            display:'flex',
            justifyContent:'center',
            marginTop:'20%',
            marginBottom:'10%'
        },
    },
}))