import React from 'react'
import styles from './styles'
import {Button} from '@material-ui/core'

const StudieCard = ({props}) => {
    const cardContainer = styles().cardContainer
    const boxContainer = styles().boxContainer
    const cardTitle = styles().cardTitle
    const titleText = styles().titleText
    const cardDescription = styles().cardDescription
    const descText = styles().descText
    const quarterOfCircle = styles().quarterOfCircle
    const bodyIcon = styles().bodyIcon

    return(
        <div className={`${cardContainer}`} style={{backgroundImage: `url(${props.background})`, backgroundSize: 'cover'}}>
            <div className={`${boxContainer}`}>
                <div className={`${cardTitle}`}>
                    <span className={`${titleText}`}>{props.title}</span>
                </div>
                <div className={`${cardDescription}`}>
                    <span className={`${descText}`}>{props.text}</span>
                </div>
                <div className={`${quarterOfCircle}`}>
                </div>
                <div className={`${bodyIcon}`}>
                    {props.icon}
                </div>
            </div>            
        </div>
    )
}

export default StudieCard