import React from 'react'
import Header from '../Home/Header'
import MenuBar from '../Home/MenuBar'
import Footer from '../Home/footer'
import styles from './styles.js'
import bg from '../../assets/underconstruction.jpg'

export const EnConstruccion = () => {
    const background = styles().background
    const centered = styles().centered

    return (
        <>
            <Header />
            <MenuBar />
            <div className={`${centered}`}>
                <div className={`${background}`} style={{backgroundImage: `url(${bg})`}}>                
                </div>
            </div>
            <Footer />
        </>
    )
}

export default EnConstruccion