import React from 'react'
import styles from './styles'
import {Button} from '@material-ui/core'

const Cards = ({props}) => {
    const cardContainer = styles().cardContainer
    const cardIndex = styles().cardIndex
    const indexText = styles().indexText
    const cardTitle = styles().cardTitle
    const titleText = styles().titleText
    const cardDescription = styles().cardDescription
    const descText = styles().descText
    const bodyItemRightButton = styles().bodyItemRightButton
    const quarterOfCircle = styles().quarterOfCircle
    const bodyIcon = styles().bodyIcon

    return(
        <div className={`${cardContainer}`}>
            <div className={`${cardIndex}`}>
                <span className={`${indexText}`}>{props.index}</span>
            </div>
            <div className={`${cardTitle}`}>
                <span className={`${titleText}`}>{props.title}</span>
            </div>
            <div className={`${cardDescription}`}>
                <span className={`${descText}`}>{props.text}</span>
            </div>
            {/*<div className={`${bodyItemRightButton}`}>
                <Button variant="contained" color="primary">
                    LEARN MORE
                </Button>
    </div>*/}
            <div className={`${quarterOfCircle}`}>
            </div>
            <div className={`${bodyIcon}`}>
                {props.icon}
            </div>
        </div>
    )
}

export default Cards