import React from 'react'
import styles from './styles'
import Cards from '../utils/cards'
import CardsDown from '../utils/cardsDown'
import DevicesOtherIcon from '@material-ui/icons/DevicesOther'
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SecurityIcon from '@material-ui/icons/Security'
import background_1 from "../../../assets/bg-counter-1.jpg";
import background_2 from "../../../assets/bg-counter-2.jpg";

const cards = [
    {   
        index: '01',
        title: 'Diseño',
        text: 'Nuestro servicio de diseño de productos le permite crear prototipos, probar y validar sus ideas.',
        icon: <DevicesOtherIcon fontSize="large"/>
    },
    {
        index: '02',
        title: 'Desarrollo',
        text: 'Expertos en sistemas y software desarrollarán su idea hasta convertirla en el producto final',
        icon: <DeveloperModeIcon fontSize="large"/>
    },
    {
        index: '03',
        title: 'Analíticas',
        text: 'Revisa los datos necesarios para elevar su producto al siguiente nivel con nuestro servicio de Analíticas',
        icon: <AssessmentIcon fontSize="large"/>
    },
    {
        index: '04',
        title: 'Ciberseguridad',
        text: 'Por que la seguridad es importante, nuestro equipo de ciberseguridad diseñará su producto para evitar intrusiones',
        icon: <SecurityIcon fontSize="large"/>
    }
]

const cardsDown = [
    {   
        index: '05',
        title: '15+',
        subTitle: 'Campos de acción',
        text: 'Desarrollamos sistemas en distintas áreas del hardware y software incluyendo las tecnologías emergentes como principal herramienta',
        background: background_1
    },
    {   
        index: '06',
        title: '5+',
        subTitle: 'Años de experiencia',
        text: 'Para tener éxito, cada solución de software debe estar profundamente integrada en el entorno tecnológico existente.',
        background: background_2
    },
]

const WhyChooseUs = () => {
    const wcuContainer = styles().wcuContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const descriptionContent = styles().descriptionContent
    const descriptionText = styles().descriptionText
    const divCardsContainer = styles().divCardsContainer

    return(
        <div className={`${wcuContainer}`}>
            <div className={`${divTextContainer}`}>
                <div className={`${titleContent}`}>
                    <span>// POR QUÉ ELEGIRNOS?</span>
                </div>
                <div className={`${descriptionContent}`}>
                    <div className={`${descriptionText}`}>
                        <span>Diseña un concepto nuevo o una idea de negocios ahora!</span>
                    </div>
                </div>
            </div>
            <div className={`${divCardsContainer}`}>
                {cards.map( (item, i) => <Cards key={i} props={item} /> )}
            </div>
            <div className={`${divCardsContainer}`}>
                {cardsDown.map( (item, i) => <CardsDown key={i} props={item} /> )}
            </div>
        </div>
    )
}

export default WhyChooseUs