import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../MainTheme'

export default makeStyles(() => ({    
    background:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            height:"500px",
            paddingTop:"15%",
            display:'flex',
            flexDirection:'column', 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: '400px 250px', 
            backgroundPosition:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            height:"300px",
            paddingTop:"5%",
            display:'flex',
            flexDirection:'row', 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: '500px 300px', 
            backgroundPosition:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            height:"400px",
            paddingTop:"5%",
            display:'flex',
            flexDirection:'row',
            backgroundRepeat: 'no-repeat', 
            backgroundSize: '600px 400px', 
            backgroundPosition:'center'
        },
    },
    centered:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            height:"100%",
            display:'felx',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            height:"100%",
            display:'felx',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            height:"100%",
            display:'felx',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
    }
}))