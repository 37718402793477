import React from 'react'
import styles from './styles.js'
import imageBody from '../../../assets/image1-home1.png'
import CenterFocusWeakOutlinedIcon from '@material-ui/icons/CenterFocusWeakOutlined'
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined'
import {Button} from '@material-ui/core'

import '../../MainTheme/theme.css'


const BodyMiddle = () => {
    const bodyContainer = styles().bodyContainer
    const bodyItemLeft = styles().bodyItemLeft
    const bodyItemRight = styles().bodyItemRight

    const bodyTitle = styles().bodyTitle
    const bodySubTitle = styles().bodySubTitle
    const bodyDescription = styles().bodyDescription
    const bodyBottom = styles().bodyBottom
    const bodyBottomLeft = styles().bodyBottomLeft
    const bodyBottomRight = styles().bodyBottomRight
    const bodyIcon = styles().bodyIcon
    const bodyIconTitle = styles().bodyIconTitle
    const bodyIconDescription = styles().bodyIconDescription
    const bodyItemRightButton = styles().bodyItemRightButton
    const bodyItemRightImage = styles().bodyItemRightImage

    return (
        <div className={`${bodyContainer}`}>
            <div className={`${bodyItemLeft}`}>
                <div className={`${bodyTitle}`}>
                    <span>// SOBRE LA EMPRESA</span>
                </div>
                <div className={`${bodySubTitle}`}>
                    <span>Su aliado para la innovación en software</span>
                </div>
                <div className={`${bodyDescription}`}>
                    <span>Scientific Software es el socio elegido por muchas de las principales empresas, pymes y desafíos tecnológicos del mundo. Ayudamos a las empresas a elevar su valor a través del desarrollo de software personalizado, diseño de productos, control de calidad y servicios de consultoría.</span>
                </div>
                <div className={`${bodyBottom}`}>                    
                    <div className={`${bodyBottomLeft}`}>
                        <div className={`${bodyIcon}`}>
                            <CenterFocusWeakOutlinedIcon fontSize="large"/>
                        </div>
                        <div className={`${bodyIconTitle}`}>
                            <span>Experiencia</span>
                        </div>
                        <div className={`${bodyIconDescription}`}>
                            <span>Profesionales calificados altamente expermientados en el desarrollo de sistemas</span>
                        </div>
                    </div>
                    <div className={`${bodyBottomRight}`}>
                        <div className={`${bodyIcon}`}>
                            <GpsFixedOutlinedIcon fontSize="large"/>
                        </div>                        
                        <div className={`${bodyIconTitle}`}>
                            <span>Capacidad de Soporte</span>
                        </div>    
                        <div className={`${bodyIconDescription}`}>
                            <span>Ofrecemos el soporte para la prueba y retroalimentación de nuevas ideas</span>
                        </div>          
                    </div>
                </div>
            </div>
            <div className={`${bodyItemRight}`}>
                <div className={`${bodyItemRightImage}`}>
                    <img src={imageBody}/>
                </div>
                <div className={`${bodyItemRightButton}`}>
                    <Button variant="contained" color="primary">
                        MAS SOBRE NOSOTROS
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BodyMiddle