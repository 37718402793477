import React from 'react'
import {IconButton} from '@material-ui/core'
import styles from './styles.js'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import InstagramIcon from '@material-ui/icons/Instagram'
import CallIcon from '@material-ui/icons/Call'
import MailIcon from '@material-ui/icons/Mail'

import '../../MainTheme/theme.css'

const Header = () => {
    const headerContainer = styles().headerContainer
    const headerContainerLeft = styles().headerContainerLeft
    const headerContainerRight = styles().headerContainerRight
    const socialButton = styles().socialButton
    const textDiv = styles().textDiv

    return (
        <div className={`${headerContainer}`}>
            <div className={`${headerContainerLeft}`}>
                <div className={`${socialButton} social-border-left`}>
                    <IconButton size="small" color="inherit">
                        <TwitterIcon />
                    </IconButton>
                </div>
                <div className={`${socialButton} social-border-left`}>
                    <IconButton size="small" color="inherit">
                        <FacebookIcon />
                    </IconButton>
                </div>
                <div className={`${socialButton} social-border-left`}>
                    <IconButton size="small" color="inherit">
                        <LinkedInIcon />
                    </IconButton>
                </div>
                <div className={`${socialButton} social-border-left social-border-right`}>
                    <IconButton size="small" color="inherit">
                        <InstagramIcon />
                    </IconButton>
                </div>
            </div>
            <div className={`${headerContainerRight}`}>
                <div className={`${socialButton} social-border-left`}>
                    <CallIcon />
                    <div className={`${textDiv}`}>
                        +51-999-999-999
                    </div>
                </div>
                <div className={`${socialButton} social-border-left social-border-right`}>
                    <MailIcon />
                    <div className={`${textDiv}`}>
                        info@scientificsw.com
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header