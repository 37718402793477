import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    sliderContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            justifyContent:'center',
            paddingTop:"6%",
            paddingBottom:"6%",
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            justifyContent:'center',
            paddingTop:"4%",
            paddingBottom:"4%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            justifyContent:'center',
            paddingTop:"4%",
            paddingBottom:"4%",
        },
    },
    carouselContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"70%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"70%",
        },
    },
    carouselItems:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"200px",
            paddingLeft:"15%"
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"200px",
            paddingLeft:"15%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"200px",
            paddingLeft:"15%"
        },
    },
}))