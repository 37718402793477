import React from 'react'
import styles from './styles.js'
import {Button} from '@material-ui/core'
import ServiceCard from '../utils/serviceCard/index.js'
import WebIcon from '@material-ui/icons/Web';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import ContactsIcon from '@material-ui/icons/Contacts';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ComputerIcon from '@material-ui/icons/Computer';
import SettingsIcon from '@material-ui/icons/Settings';

const serviceCards_1= [
    {   
        index: '01',
        title: 'Desarrollo Web',
        text: 'Contamos con más que buenas habilidades de codificación. Nuestra experiencia nos distingue de otros desarrolladores web.',
        icon: <WebIcon fontSize="large"/>
    },
    {   
        index: '02',
        title: 'Desarrollo Movil',
        text: 'Cree experiencias móviles enriquecidas por fuera y complejas por dentro con un correcto desarrollo',
        icon: <PhonelinkSetupIcon fontSize="large"/>
    },
    {   
        index: '03',
        title: 'Desarrollo UI/UX',
        text: 'Cree el producto que necesita a tiempo con un equipo experimentado que utiliza un proceso de diseño claro y eficaz.',
        icon: <ContactsIcon fontSize="large"/>
    },
]

const serviceCards_2 = [
    {   
        index: '04',
        title: 'QA y Testing',
        text: 'Recurra a nuestros expertos para que realicen pruebas y auditorías integrales en varias etapas de su software.',
        icon: <EqualizerIcon fontSize="large"/>
    },
    {   
        index: '05',
        title: 'Consultorías IT',
        text: 'Confíe en nuestras mentes principales para eliminar los puntos débiles del flujo de trabajo, implementar nuevas tecnologías y consolidar las carteras de aplicaciones.',
        icon: <ComputerIcon fontSize="large"/>
    },
    {   
        index: '06',
        title: 'Desarrollo de Software',
        text: 'Cree software científico y empresarial complejo, garantice una integración de software confiable, modernice su sistema heredado.',
        icon: <SettingsIcon fontSize="large"/>
    },
]

const OurService = () => {
    const osContainer = styles().osContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const descriptionContent = styles().descriptionContent
    const allServicesContainer = styles().allServicesContainer
    const buttonContainer = styles().buttonContainer
    const divCardsContainer = styles().divCardsContainer

    return (
        <div className={`${osContainer}`}>     
            <div className={`${allServicesContainer}`}>
                <div className={`${divTextContainer}`}>
                    <div className={`${titleContent}`}>
                        <span>// NUESTRO SERVICIO</span>
                    </div>
                    <div className={`${descriptionContent}`}>
                        <span>Ofrecemos una amplia variedad de servicios de IT</span>
                    </div>
                </div>
                <div className={`${buttonContainer}`}>
                    <Button variant="contained" color="primary">
                        TODOS LOS SERVICIOS
                    </Button>
                </div>
            </div>       
            <div className={`${divCardsContainer}`}>
                {serviceCards_1.map( (item, i) => <ServiceCard key={i} props={item} /> )}
            </div>      
            <div className={`${divCardsContainer}`}>
                {serviceCards_2.map( (item, i) => <ServiceCard key={i} props={item} /> )}
            </div>   
        </div>
    )
}

export default OurService
