import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    osContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"10%"
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"2%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"2%"
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"5%",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"5%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"15%",
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
    },
    descriptionContent:{
        [MainTheme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "40px",
            paddingTop: "2%",
            width:'100%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main,
            textAlign: 'left',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            paddingTop: "2%",
            width:'60%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main,
            textAlign: 'left',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            paddingTop: "2%",
            width:'60%'
        },
    },
    allServicesContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
        },
    },
    buttonContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-end',
            paddingLeft:"5%",
            paddingTop:'2%',
            paddingBottom:"10%"
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-end',
            paddingRight:"5%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-end',
            paddingRight:"15%",
        },
    },
    divCardsContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"95%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:"2%",
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:"2%",
            color: MainTheme.palette.primary.dark_full,
        },
    },
}))