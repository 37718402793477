import React from 'react'
import styles from './styles.js'
import StudieCard from '../utils/studieCard/index.js'
import {Carousel} from '3d-react-carousal';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import MemoryIcon from '@material-ui/icons/Memory';
import StoreIcon from '@material-ui/icons/Store';
import SecurityIcon from '@material-ui/icons/Security'
import project_1 from "../../../assets/project8-720x520.jpg";
import project_2 from "../../../assets/project7-720x520.jpg";
import project_3 from "../../../assets/IA.jpg";
import project_4 from "../../../assets/project3-720x520.jpg";
import project_5 from "../../../assets/project-5.jpg";
import '../../MainTheme/theme.css'

const cards = [
        {   
            index: '01',
            title: 'Proyectos Básicos',
            text: 'DISEÑO / DESARROLLO',
            icon: <AccountTreeIcon fontSize="large"/>,
            background: project_1
        },
        {
            index: '02',
            title: 'Redes Sociales',
            text: 'POSICIONAMIENTO / EXPANSIÓN',
            icon: <PermMediaIcon fontSize="large"/>,
            background: project_2
        },
        {
            index: '03',
            title: 'Inteligencia Artificial',
            text: 'MODELADO / ENTRENAMIENTO',
            icon: <MemoryIcon fontSize="large"/>,
            background: project_3
        },
        {
            index: '04',
            title: 'eCommerce Websites',
            text: 'ESTRUCTURA / ALMACENAMIENTO',
            icon: <StoreIcon fontSize="large"/>,
            background: project_4
        },
        {
            index: '05',
            title: 'Ciberseguridad',
            text: 'ANÁLISIS / PROTECCIÓN',
            icon: <SecurityIcon fontSize="large"/>,
            background: project_5
        }
]

let slides = [
    <StudieCard key={'01'} props={cards[0]} />,
    <StudieCard key={'02'} props={cards[1]} />,
    <StudieCard key={'03'} props={cards[2]} />,
    <StudieCard key={'04'} props={cards[3]} />,
    <StudieCard key={'05'} props={cards[4]} />
];

const CaseStudies = () => {    

    const osContainer = styles().osContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const subTitleContent = styles().subTitleContent
    const allServicesContainer = styles().allServicesContainer
    const descriptionContainer = styles().descriptionContainer
    const divCardsContainer = styles().divCardsContainer

    return (
        <div className={`${osContainer}`}>     
            <div className={`${allServicesContainer}`}>
                <div className={`${divTextContainer}`}>
                    <div className={`${titleContent}`}>
                        <span>// ULTIMOS CASOS</span>
                    </div>
                    <div className={`${subTitleContent}`}>
                        <span>Conoce nuestros proyectos</span>
                    </div>
                </div>
                <div className={`${descriptionContainer}`}>
                    <span>La subcontratación de desarrollo de software es solo una herramienta para lograr los objetivos comerciales. Pero no hay forma de obtener resultados que valgan la pena sin la cooperación y la confianza entre una empresa cliente.</span>
                </div>
            </div>    
            <div className={`${divCardsContainer} noselect`}>
                <Carousel slides={slides}/>
            </div>
        </div>
    )
}

export default CaseStudies