import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    osContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"2%",
            paddingBottom:"10%"
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"2%",
            paddingBottom:"4%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            paddingTop:"2%",
            paddingBottom:"4%"
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"5%",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"5%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main,
            paddingLeft:"15%",
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            paddingTop: "2%",
            textAlign: 'left',
            fontSize: "15px",
            fontWeight: "bold",
        },
    },
    subTitleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main,
            textAlign: 'left',
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "50px",
            paddingTop: "2%",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main,
            textAlign: 'left',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            paddingTop: "2%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main,
            textAlign: 'left',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            paddingTop: "2%",
        },
    },
    allServicesContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
        },
    },
    descriptionContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            paddingLeft:'5%',
            paddingTop:'2%',
            width:'90%',
            lineHeight: "35px",
            fontWeight:'lighter',
            color: MainTheme.palette.secondary.main,
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-end',
            paddingRight:'15%',
            width:'35%',
            lineHeight: "25px",
            color: MainTheme.palette.secondary.main,
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-end',
            paddingRight:'15%',
            width:'35%',
            lineHeight: "25px",
            color: MainTheme.palette.secondary.main,
        },
    },
    divCardsContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            marginTop:'3%',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            marginTop:'3%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            marginTop:'3%',
        },
    },
}))