import {React} from 'react'
import styles from './styles'
import logo from '../../../assets/LogoSSW.svg'
import {IconButton, Link} from '@material-ui/core'
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';

const MenuBar = () => {
    const headerContainer = styles().headerContainer
    const logoContainer = styles().logoContainer
    const menuContainer = styles().menuContainer
    const buttonsMenuContainer = styles().buttonsMenuContainer
    const logoStyle = styles().logoStyle
    const menuItem = styles().menuItem
    const menuButtons = styles().menuButtons

    return (
        <div className={`${headerContainer}`}>
            <div className={`${logoContainer}`}>
                <img src={logo} className={`${logoStyle}`} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
            </div>
            <div className={`${menuContainer}`}>
                <div className={`${menuItem}`}>
                    <Link href="./" color="inherit">
                        {'Inicio'}
                    </Link>
                </div>
                <div className={`${menuItem}`}>
                    <Link href="./nosotros" color="inherit">
                        {'Nosotros'}
                    </Link>
                </div>
                <div className={`${menuItem}`}>
                    <Link href="./servicios" color="inherit">
                        {'Servicios'}
                    </Link>
                </div>
                <div className={`${menuItem}`}>
                    <Link href="./proyectos" color="inherit">
                        {'Proyectos'}
                    </Link>
                </div>
                <div className={`${menuItem}`}>
                    <Link href="./blog" color="inherit">
                        {'Blog'}
                    </Link>
                </div>
                <div className={`${menuItem}`}>
                    <Link href="./contacto" color="inherit">
                        {'Contacto'}
                    </Link>
                </div>
            </div>
            <div className={`${buttonsMenuContainer}`}>                
                <div className={`${menuButtons}`}>
                    <IconButton size="medium" color="inherit">
                        <LocalMallOutlinedIcon fontSize="large"/>
                    </IconButton>
                </div>
                <div className={`${menuButtons}`}>
                    <IconButton size="medium" color="inherit">
                        <SearchOutlinedIcon fontSize="large"/>
                    </IconButton>
                </div>
                <div className={`${menuButtons}`}>
                    <IconButton size="medium" color="inherit">
                        <WidgetsOutlinedIcon fontSize="large"/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default MenuBar