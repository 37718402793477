import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    techIndexContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"20%",
            paddingBottom:"2%",
            backgroundPosition:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"6%",
            paddingBottom:"2%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"6%",
            paddingBottom:"2%",
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
    },
    descriptionContent:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'5%'
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
    },
    descriptionText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "26px",
            fontWeight: "bold",
            lineHeight: "35px",
            width:'100%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "50px",
            width:'90%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
            width:'60%'
        },
    },
    divCardsContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            color: MainTheme.palette.primary.dark_full,
        },
    },
}))