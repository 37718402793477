import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 1120
const height = 320
const marginLeft = 10
const marginRight = 10

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            //width: `${(width+marginLeft+marginRight)/3}px`,
            width: '90%',
            display:'flex',
            flexDirection:'row',            
            marginLeft:'10px',
            marginRight:'10px',
            marginBottom:'20px',
        },
        [MainTheme.breakpoints.up('md')]: {        
            width: `${(width+marginLeft+marginRight)/3}px`,
            display:'flex',
            flexDirection:'row',            
            marginLeft:'10px',
            marginRight:'10px',
            marginBottom:'20px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${(width+marginLeft+marginRight)/3}px`,
            display:'flex',
            flexDirection:'row',            
            marginLeft:'10px',
            marginRight:'10px',
            marginBottom:'20px',
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            display:'flex',
            alignContent:'start',
            paddingLeft:'10px',
            paddingTop:'10px'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.light_full,
            display:'flex',
            alignContent:'start',
            paddingLeft:'10px',
            paddingTop:'10px'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            display:'flex',
            alignContent:'start',
            paddingLeft:'10px',
            paddingTop:'10px'
        },
    },
    descriptionContainer:{
        [MainTheme.breakpoints.down('sm')]: {     
            width:"100%",
            display:'flex',
            flexDirection:'column',   
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"100%",
            display:'flex',
            flexDirection:'column',       
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            flexDirection:'column',       
        },
    },
    cardTitle:{
        [MainTheme.breakpoints.down('sm')]: {   
            width:"100%",
            display:'flex',
            paddingLeft:'20px',
            paddingTop:'10px'
        },
        [MainTheme.breakpoints.up('md')]: {     
            width:"100%",
            display:'flex',
            paddingLeft:'20px',
            paddingTop:'10px'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            paddingLeft:'20px',
            paddingTop:'10px'
        },
    },
    titleText:{
        [MainTheme.breakpoints.down('sm')]: { 
            color:MainTheme.palette.secondary.main,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {    
            color:MainTheme.palette.secondary.main,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.secondary.main,
            fontSize:'26px',
            fontWeight:'bold'
        },
    },
    cardDescription:{
        [MainTheme.breakpoints.down('sm')]: {  
            width:"100%",
            display:'flex',
            paddingLeft:'20px'
        },
        [MainTheme.breakpoints.up('md')]: {        
            width:"100%",
            display:'flex',
            paddingLeft:'20px'
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"100%",
            display:'flex',
            paddingLeft:'20px'
        },
    },
    descText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('md')]: {  
            color: MainTheme.palette.secondary.main,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color: MainTheme.palette.secondary.main,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
    }    
}))