import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    carouselContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            position: "relative",
            height:'400px',
            width:'100%',
            backgroundPosition:'center',
            backgroundSize: 'cover'
        },
        [MainTheme.breakpoints.up('md')]: {
            position: "relative",
            width:'100%',
            height:'650px',
            backgroundPosition:'center',
            backgroundSize: 'cover'
        },
        [MainTheme.breakpoints.up('lg')]: {
            position: "relative",
            width:'100%',
            height:'850px',
            backgroundPosition:'center',
            backgroundSize: 'cover'
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            position: "absolute",
            top: "25%",
            left: "10%",
            color: MainTheme.palette.secondary.contrastText,
        },
        [MainTheme.breakpoints.up('md')]: {
            position: "absolute",
            top: "25%",
            left: "15%",
            color: MainTheme.palette.secondary.contrastText,
        },
        [MainTheme.breakpoints.up('lg')]: {
            position: "absolute",
            top: "25%",
            left: "15%",
            color: MainTheme.palette.secondary.contrastText,
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.contrastText,
            display:'none'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.contrastText,
            display:'none'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.contrastText,
        },
    },
    descriptionFirstContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "30px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "50px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "80px",
            fontWeight: "bold",
        },
    },
    descriptionSecondContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "30px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "50px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "80px",
            fontWeight: "bold",
        },
    },
    descriptionThirdContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "15px",
            width:"80%",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "20px",
            width:"70%",
            fontWeight:"lighter",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.contrastText,
            fontSize: "20px",
            width:"70%",
            fontWeight:"lighter",
        },
    },
    buttonContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            paddingTop:"3%",
            display:'flex',
            justifyContent:'flex-start'
        },
        [MainTheme.breakpoints.up('md')]: {
            paddingTop:"3%",
            display:'flex',
            justifyContent:'flex-start'
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingTop:"3%",
            display:'flex',
            justifyContent:'flex-start'
        },
    },

}))