import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 1120
const height = 320
const marginLeft = 10
const marginRight = 10

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width: `100%`,
            height: `270px`,
            position:'relative',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            width: `${width/4}px`,
            height: `${height}px`,
            position:'relative',
            marginLeft:'10px',
            marginRight:'10px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width: `${width/4}px`,
            height: `${height}px`,
            position:'relative',
            marginLeft:'10px',
            marginRight:'10px',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
    },
    cardIndex:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"10%",
            position:'absolute',
            top:'0',
            left:'0',
            backgroundColor: MainTheme.palette.primary.dark_full
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"10%",
            position:'absolute',
            top:'0',
            left:'0',
            backgroundColor: MainTheme.palette.primary.dark_full
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"10%",
            position:'absolute',
            top:'0',
            left:'0',
            backgroundColor: MainTheme.palette.primary.dark_full
        },
    },
    indexText:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'64px',
            opacity:'0.2',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {    
            color:MainTheme.palette.primary.contrastText,
            fontSize:'54px',
            opacity:'0.2',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            fontSize:'64px',
            opacity:'0.2',
            fontWeight:'bold'
        },
    },
    cardTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('md')]: {    
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
    },
    titleText:{
        [MainTheme.breakpoints.down('sm')]: {  
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {   
            color:MainTheme.palette.primary.contrastText,
            fontSize:'20px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
    },
    cardDescription:{
        [MainTheme.breakpoints.down('sm')]: {         
            width:"80%",
            position:'absolute',
            top:'76px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"80%",
            position:'absolute',
            top:'76px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'76px',
            left:'32px',
        },
    },
    descText:{
        [MainTheme.breakpoints.down('sm')]: {    
            color: MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('md')]: {   
            color: MainTheme.palette.primary.contrastText,
            fontSize:'14px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color: MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
    },
    bodyItemRightButton:{
        [MainTheme.breakpoints.down('sm')]: {
            position:'absolute',
            bottom:'20%',
            left:'5%',
        },
        [MainTheme.breakpoints.up('md')]: {
            position:'absolute',
            bottom:'20%',
            left:'5%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            position:'absolute',
            bottom:'20%',
            left:'5%',
        },
    },
    quarterOfCircle:{
        [MainTheme.breakpoints.down('sm')]: {
            position:'absolute',
            bottom:'-110px',
            right:'-110px',
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:'220px',
            height:'220px',
            borderRadius:'200px',
        },
        [MainTheme.breakpoints.up('md')]: {
            position:'absolute',
            bottom:'-110px',
            right:'-110px',
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:'220px',
            height:'220px',
            borderRadius:'200px',
        },
        [MainTheme.breakpoints.up('lg')]: {
            position:'absolute',
            bottom:'-110px',
            right:'-110px',
            backgroundColor: MainTheme.palette.primary.contrastText,
            opacity:'0.15',
            width:'220px',
            height:'220px',
            borderRadius:'200px',
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'7%',
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'7%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.contrastText, 
            position:'absolute',
            bottom:'5%',
            right:'7%',
        },
    },
}))