import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    bodyContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            paddingTop:"15%",
            display:'flex',
            flexDirection:'column'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            paddingTop:"5%",
            display:'flex',
            flexDirection:'row'
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            paddingTop:"5%",
            display:'flex',
            flexDirection:'row'
        },
    },
    bodyItemLeft:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"90%",
            paddingLeft:"5%",
            display:'flex',
            flexDirection:'column',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"45%",
            paddingLeft:"5%",
            display:'flex',
            flexDirection:'column'
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"35%",
            paddingLeft:"15%",
            display:'flex',
            flexDirection:'column'
        },
    },
    bodyItemRight:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"95%",
            display:'flex',
            position:'relative'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"50%",
            display:'flex',
            position:'relative'
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"50%",
            display:'flex',
            position:'relative'
        },
    },
    bodyTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            fontSize: "12px",
            fontWeight: "bold"
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.light_full,
            fontSize: "15px",
            fontWeight: "bold"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            fontSize: "15px",
            fontWeight: "bold"
        },
    },
    bodySubTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.dark,
            fontSize: "25px",
            fontWeight: "bold",
            width:"100%",
            paddingTop:"2%",
            lineHeight: "35px"
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.dark,
            fontSize: "40px",
            fontWeight: "bold",
            width:"80%",
            paddingTop:"2%",
            lineHeight: "50px"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.dark,
            fontSize: "40px",
            fontWeight: "bold",
            width:"80%",
            paddingTop:"2%",
            lineHeight: "50px"
        },
    },
    bodyDescription:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.light, 
            paddingTop:"2%",  
            width:"100%",
            lineHeight: "25px",
            fontWeight: "lighter",
            fontSize:'16px'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.light, 
            paddingTop:"2%",  
            width:"90%",
            lineHeight: "30px"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.light, 
            paddingTop:"2%",  
            width:"80%",
            lineHeight: "30px"
        },
    },
    bodyBottom:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.light, 
            width:"90%",
            display:'flex',
            flexDirection:'column'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.light, 
            width:"100%",
            display:'flex',
            flexDirection:'row'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.light, 
            width:"80%",
            display:'flex',
            flexDirection:'row'
        },
    },
    bodyBottomLeft:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.dark, 
            display:'flex',
            flexDirection:'column',
            paddingTop: "5%",
            fontWeight: "lighter",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.dark, 
            display:'flex',
            flexDirection:'column',
            paddingTop: "5%",
            width:"100%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark, 
            display:'flex',
            flexDirection:'column',
            paddingTop: "5%",
            width:"50%"
        },
    },
    bodyBottomRight:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            flexDirection:'column',
            fontWeight: "lighter",
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            flexDirection:'column',
            width:"100%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            flexDirection:'column',
            width:"50%"
        },
    },
    bodyIcon:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%"
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%"
        },
    },
    bodyIconTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "20px",
            fontWeight: 'bold'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "20px",
            fontWeight: 'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.dark, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "20px",
            fontWeight: 'bold'
        },
    },
    bodyIconDescription:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "15px",
            lineHeight: "30px"
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "15px",
            lineHeight: "30px"
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main, 
            paddingTop: "5%",
            display:'flex',
            width:"100%",
            fontSize: "15px",
            lineHeight: "30px"
        },
    },
    bodyItemRightImage:{
        [MainTheme.breakpoints.down('sm')]: {
            overflow:'hidden',
            width:'100%',
        },
        [MainTheme.breakpoints.up('md')]: {
            overflow:'hidden',
            width:'100%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            overflow:'hidden',
            width:'100%',
        },
    },
    bodyItemRightButton:{
        [MainTheme.breakpoints.down('sm')]: {
            position:'absolute',
            bottom:'15%',
            left:'5%',
        },
        [MainTheme.breakpoints.up('md')]: {
            position:'absolute',
            bottom:'10%',
            left:'5%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            position:'absolute',
            bottom:'10%',
            left:'5%',
        },
    },
}))