import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    wwContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"4%",
            paddingBottom:"10%",
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"4%",
            paddingBottom:"2%",
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingTop:"4%",
            paddingBottom:"2%",
        },
    },
    divTextContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.dark_full,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignContent:'center',
        },
    },
    titleContent:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "12px",
            fontWeight: "bold",
            paddingBottom:'2%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.primary.light_full,
            textAlign: 'center',
            fontSize: "15px",
            fontWeight: "bold",
        },
    },
    descriptionContent:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            paddingTop:'1%',
            paddingBottom:'2%'
        },
    },
    descriptionText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.secondary.main,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "26px",
            fontWeight: "bold",
            lineHeight: "30px",
            paddingBottom:'4%'
        },
        [MainTheme.breakpoints.up('md')]: {
            color: MainTheme.palette.secondary.main,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
        },
        [MainTheme.breakpoints.up('lg')]: {
            color: MainTheme.palette.secondary.main,
            display:'flex',
            flexDirection:'row',
            textAlign: 'center',
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "50px",
        },
    },
    divCardsContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            color: MainTheme.palette.primary.dark_full,
            marginTop:'3%',
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            color: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            color: MainTheme.palette.primary.dark_full,
        },
    },
}))