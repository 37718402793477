import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({    
    footerContent:{
        [MainTheme.breakpoints.down('sm')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            paddingTop:"10%",
            paddingBottom:"20%",
            backgroundColor:MainTheme.palette.primary.dark
        },
        [MainTheme.breakpoints.up('md')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            paddingTop:"4%",
            paddingBottom:"2%",
            backgroundColor:MainTheme.palette.primary.dark
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:"100%",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            paddingTop:"4%",
            paddingBottom:"2%",
            backgroundColor:MainTheme.palette.primary.dark
        },
    },
    logo:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
        },
    },
    companyInfo:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'80%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'space-evenly',
            paddingTop:'40px',
            paddingBottom:'10%'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'80%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-evenly',
            paddingTop:'40px',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'80%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-evenly',
            paddingTop:'40px',
        },
    },
    address:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
    },
    mail:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
    },
    phone:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.contrastText,
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
    },
    title:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'20px',
            paddingTop:'5%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'18px',
            paddingTop:'10%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'20px',
            paddingTop:'10%',
            textAlign:'center'
        },
    },
    subTitle:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            paddingTop:'5%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            paddingTop:'10%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            paddingTop:'10%',
            textAlign:'center'
        },
    },
    icon:{
        [MainTheme.breakpoints.down('sm')]: {
            color:MainTheme.palette.primary.light_full,
            paddingTop:'15%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.light_full,
            paddingTop:'10%',
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            color:MainTheme.palette.primary.light_full,
            paddingTop:'10%',
            textAlign:'center'
        },
    },
    links:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-evenly',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'50%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-evenly',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'50%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-evenly',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
    },
    copyRight:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems:'center',
            paddingTop:'4%',
            color:MainTheme.palette.primary.contrastText,
        },
    },
    copyRightText:{
        [MainTheme.breakpoints.down('sm')]: {
            fontSize:'16px',
            color:MainTheme.palette.primary.contrastText,
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            fontSize:'16px',
            color:MainTheme.palette.primary.contrastText,
            textAlign:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            fontSize:'16px',
            color:MainTheme.palette.primary.contrastText,
            textAlign:'center'
        },
    },
}))