import React from 'react'
import styles from './styles'
import TechCard from '../utils/techCard'
import ComputerIcon from '@material-ui/icons/Computer';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MemoryIcon from '@material-ui/icons/Memory';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SdStorageIcon from '@material-ui/icons/SdStorage';
import background from "../../../assets/bg-tech-home1.jpg";
import '../../MainTheme/theme.css'

const cards = [
    {   
        index: '01',
        title: 'Desarrollo Web',
        icon: <ComputerIcon fontSize="large"/>
    },
    {
        index: '02',
        title: 'Desarrollo Móvil',
        icon: <MobileFriendlyIcon fontSize="large"/>
    },
    {
        index: '03',
        title: 'Inteligencia Artificial',
        icon: <MemoryIcon fontSize="large"/>
    },
    {
        index: '04',
        title: 'Internet de las Cosas',
        icon: <SettingsInputAntennaIcon fontSize="large"/>
    },
    {
        index: '05',
        title: 'Modelos Numéricos',
        icon: <AllInclusiveIcon fontSize="large"/>
    },
    {
        index: '06',
        title: 'Hardware & Firmware',
        icon: <SdStorageIcon fontSize="large"/>
    }
]

const TechIndex = () => {
    const techIndexContainer = styles().techIndexContainer
    const divTextContainer = styles().divTextContainer
    const titleContent = styles().titleContent
    const descriptionContent = styles().descriptionContent
    const descriptionText = styles().descriptionText
    const divCardsContainer = styles().divCardsContainer

    return(
        <div className={`${techIndexContainer}`} style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}>
            <div className={`${divTextContainer}`}>
                <div className={`${titleContent}`}>
                    <span>// ÍNDICE TECNOLÓGICO</span>
                </div>
                <div className={`${descriptionContent}`}>
                    <div className={`${descriptionText}`}>
                        <span>Brindamos soluciones con el objetivo de establecer relaciones de confianza en los siguientes campos de la tecnología</span>
                    </div>
                </div>
            </div>
            <div className={`${divCardsContainer} noselect`}>
                {cards.map( (item, i) => <TechCard key={i} props={item} /> )}
            </div>
        </div>
    )
}

export default TechIndex