import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    headerContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            backgroundColor: MainTheme.palette.primary.contrastText,
            width:'100%',
            height: '70px',
            display:'flex',
            flexDirection:'row',
        },
        [MainTheme.breakpoints.up('md')]: {
            backgroundColor: MainTheme.palette.primary.contrastText,
            width:'100%',
            height: '100px',
            display:'flex',
            flexDirection:'row',
        },
        [MainTheme.breakpoints.up('lg')]: {
            backgroundColor: MainTheme.palette.primary.contrastText,
            width:'100%',
            height: '100px',
            display:'flex',
            flexDirection:'row',
        },
    },
    logoContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            paddingLeft:'2%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            paddingLeft:'15%',
            width:'60%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingLeft:'15%',
            width:'60%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        },
    },
    menuContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'none'
        },
        [MainTheme.breakpoints.up('md')]: {
            width:'100%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            width:'100%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
        },
    },
    buttonsMenuContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            width:'100%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'flex-end',    
        },
        [MainTheme.breakpoints.up('md')]: {
            paddingRight:'15%',
            width:'50%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'flex-end'
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingRight:'15%',
            width:'50%',
            height: '100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'flex-end'
        },
    },
    logoStyle:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        },
        [MainTheme.breakpoints.up('lg')]: {
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        },
    },
    menuItem:{
        [MainTheme.breakpoints.down('sm')]: {
        },
        [MainTheme.breakpoints.up('md')]: {
            paddingLeft:'5%'
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingLeft:'5%'
        },
    },
    menuButtons:{
        [MainTheme.breakpoints.down('sm')]: {
            paddingLeft: '1%',
            display:'flex',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('md')]: {
            padding: '1%',
            display:'none',
            alignItems:'center',
        },
        [MainTheme.breakpoints.up('lg')]: {
            padding: '1%',
            display:'flex',
            alignItems:'center',
        },
    },
}))