import React from 'react'
import styles from './styles'

const CardsDown = ({props}) => {
    const cardContainer = styles().cardContainer
    const cardTitle = styles().cardTitle
    const titleText = styles().titleText
    const cardSubTitle = styles().cardSubTitle
    const subTitleText = styles().subTitleText
    const cardDescription = styles().cardDescription
    const descText = styles().descText

    return(
        <div className={`${cardContainer}`} style={{backgroundImage: `url(${props.background})`, backgroundSize: 'cover'}}>
            <div className={`${cardTitle}`}>
                <span className={`${titleText}`}>{props.title}</span>
            </div>
            <div className={`${cardSubTitle}`}>
                <span className={`${subTitleText}`}>{props.subTitle}</span>
            </div>
            <div className={`${cardDescription}`}>
                <span className={`${descText}`}>{props.text}</span>
            </div>
        </div>
    )
}

export default CardsDown