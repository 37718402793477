import React from 'react'
import styles from './styles'
import logoCompany from '../../../assets/LogoSSW_Light.svg'
import bgFooter from '../../../assets/bg-footer1.png'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import {Link} from '@material-ui/core'

const Footer = () => {

    const footerContent = styles().footerContent
    const logo = styles().logo
    const companyInfo = styles().companyInfo
    const address = styles().address
    const mail = styles().mail
    const phone = styles().phone
    const links = styles().links
    const copyRight = styles().copyRight
    const copyRightText = styles().copyRightText
    const socialNetworks = styles().socialNetworks
    const title = styles().title
    const subTitle = styles().subTitle
    const icon = styles().icon

    return (
        <div className={`${footerContent}`} style={{backgroundImage: `url(${bgFooter})`, backgroundSize: 'cover'}}>
            <div className={`${logo}`}>
                <img src={logoCompany} alt={'Company logo'} alt={'Software Científico, Tecnología, Modelos Numéricos, Inteligencia Artificial, Desarrollo Web, Desarrollo de Software, Desarrollo de Hardware, Desarrollo de Firmware'}/>
            </div>
            <div className={`${companyInfo}`}>
                <div className={`${address}`}>
                    <div className={`${icon}`}>
                        <LocationOnIcon fontSize={'large'}/>
                    </div>
                    <span className={`${title}`}>Av. La Mar 2010 - San Miguel</span>
                    <span className={`${subTitle}`}>Localización</span>
                </div>
                <div className={`${mail}`}>    
                    <div className={`${icon}`}>               
                        <MailIcon fontSize={'large'}/>
                    </div> 
                    <span className={`${title}`}>info@scientificsw.com</span>
                    <span className={`${subTitle}`}>Dirección Electrónica</span>
                </div>
                <div className={`${phone}`}>  
                    <div className={`${icon}`}>      
                        <PhoneIcon fontSize={'large'}/>
                    </div>     
                    <span className={`${title}`}>+51-999-999-999</span>
                    <span className={`${subTitle}`}>Contacto móvil</span>                    
                </div>
            </div>
            <div className={`${links}`}>
                    <Link href="./" color="inherit">
                        {'Inicio'}
                    </Link>
                    <Link href="./servicios" color="inherit">
                        {'Servicios'}
                    </Link>
                    <Link href="./portafolio" color="inherit">
                        {'Portafolio'}
                    </Link>
                    <Link href="./blog" color="inherit">
                        {'Blog'}
                    </Link>
                    <Link href="./contactos" color="inherit">
                        {'Contactos'}
                    </Link>
            </div>
            <div className={`${copyRight}`}>
                    <span className={`${copyRightText}`}>Copyright © 2021 Scientific Software. All Rights Reserved.</span>                           
            </div>
            <div className={`${socialNetworks}`}>
                    
            </div>
        </div>
    )
}

export default Footer
