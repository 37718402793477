import { makeStyles } from '@material-ui/core/styles'
import MainTheme from '../../../MainTheme'

const width = 1120
const height = 280
const marginLeft = 10
const marginRight = 10

export default makeStyles(() => ({    
    cardContainer:{
        [MainTheme.breakpoints.down('sm')]: {
            display:'flex',     
            width: `100%`,
            height: `270px`,
            position:'relative',
            marginBottom:'20px',
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('md')]: {
            display:'flex',     
            width: `${width/2+marginLeft+marginRight}px`,
            height: `${height}px`,
            position:'relative',
            marginLeft:`${marginLeft}px`,
            marginRight:`${marginRight}px`,
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,
        },
        [MainTheme.breakpoints.up('lg')]: {       
            display:'flex',     
            width: `${width/2+marginLeft+marginRight}px`,
            height: `${height}px`,
            position:'relative',
            marginLeft:`${marginLeft}px`,
            marginRight:`${marginRight}px`,
            overflow:'hidden',
            border: `1px solid ${MainTheme.palette.secondary.light_mid}`,
            backgroundColor: MainTheme.palette.primary.dark_full,

        },
    },
    cardTitle:{
        [MainTheme.breakpoints.down('sm')]: {   
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('md')]: {   
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'40px',
            left:'32px',
        },
    },
    titleText:{
        [MainTheme.breakpoints.down('sm')]: {  
            color:MainTheme.palette.primary.contrastText,
            fontSize:'46px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {  
            color:MainTheme.palette.primary.contrastText,
            fontSize:'46px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            fontSize:'46px',
            fontWeight:'bold'
        },
    },
    cardSubTitle:{
        [MainTheme.breakpoints.down('sm')]: {       
            width:"80%",
            position:'absolute',
            top:'100px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('md')]: {      
            width:"80%",
            position:'absolute',
            top:'100px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'100px',
            left:'32px',
        },
    },
    subTitleText:{
        [MainTheme.breakpoints.down('sm')]: {     
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('md')]: {
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color:MainTheme.palette.primary.contrastText,
            fontSize:'26px',
            fontWeight:'bold'
        },
    },
    cardDescription:{
        [MainTheme.breakpoints.down('sm')]: {         
            width:"80%",
            position:'absolute',
            top:'140px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('md')]: {        
            width:"80%",
            position:'absolute',
            top:'140px',
            left:'32px',
        },
        [MainTheme.breakpoints.up('lg')]: {            
            width:"80%",
            position:'absolute',
            top:'140px',
            left:'32px',
        },
    },
    descText:{
        [MainTheme.breakpoints.down('sm')]: {
            color: MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('md')]: {   
            color: MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
        [MainTheme.breakpoints.up('lg')]: {        
            color: MainTheme.palette.primary.contrastText,
            fontSize:'16px',
            fontWeight:'lighter',
            lineHeight: 2,
        },
    },
}))