import Home from './components/Home/index'
import EnConstruccion from './components/EnConstruccion'

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

function App() {
  return (
    <Router>
      <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/nosotros" component={EnConstruccion} />
            <Route exact path="/servicios" component={EnConstruccion} />
            <Route exact path="/proyectos" component={EnConstruccion} />
            <Route exact path="/blog" component={EnConstruccion} />
            <Route exact path="/contacto" component={EnConstruccion} />
            <Route exact path="/portafolio" component={EnConstruccion} />
      </Switch>
    </Router>
  );
}

export default App;
